import Utils from '@/common/utils/utils';
import { Storage } from 'aws-amplify';

/**
 * uploadFileToS3
 * @param {*} filePath
 * @param {*} fileUpload
 * @returns
 */
export const uploadFileToS3 = async (filePath, fileUpload) => {
    try {
        if (!fileUpload || !fileUpload.file || Utils.isEmptyObject(fileUpload.file)) {
            return false;
        }
        if (!filePath) {
            filePath = fileUpload.key;
        }
        const file = fileUpload.file;
        let result = await Storage.put(filePath, file, {
            contentType: file.type,
            level: 'public',
            acl: "public-read"
        });

        return result;
    } catch (error) {
        // Logger.error('uploadFileToS3: Exception - ', error);
        return false;
    }
};

export const uploadFileHtmlToS3 = async (filePath, fileContent) => {
    try {
        if (!fileContent) {
            return false;
        }
        const file = new Blob([fileContent], { type: 'text/html' });
        let result = await Storage.put(filePath, file, {
            contentType: file.type,
            level: 'public',
            acl: "public-read"
        });

        return result;
    } catch (error) {
        console.log('uploadFileHtmlToS3 error', error);
        return false;
    }
}
